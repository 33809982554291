/* You can add global styles to this file, and also import other style files */
*
{
  font-family: Arial, sans-serif;
}
body
{
  margin:0;
  padding:0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
